import React, {ChangeEvent, FormEvent, useReducer, useState} from "react";
import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import Grid, {Col, Row} from "../components/grid/grid";
import styled from "styled-components";
import Img from "gatsby-image"
import {graphql} from "gatsby";
import {darkGrey} from "../styles/colors";
import {useMediaQuery} from "react-responsive";

const ContactField = styled.p`
  text-align: right;  

  a {
    display: block;
    text-decoration: none;
    color: #000000;
  }
`

const StyledGrid = styled(Grid)`
  margin-top: 60px;
  margin-bottom: 60px;
`

const StyledText = styled.textarea`
    width: 100%;
    border: 1px solid ${darkGrey};
    min-height: 150px;
    padding-left: 10px;
    border-radius: 0;
`

const StyledInput = styled.input`
  border: 1px solid ${darkGrey};
  width: 100%;
  margin-bottom: 30px;
  padding: 10px;
  border-radius: 0;
`

const SocialBlock = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Social = styled.div`
    width: 25px;
    height: 25px;
    margin-right: 15px;
    margin-bottom: 15px;
`

const Submit = styled.input`
  border: 1px solid;
  background: none;
  padding: 10px 30px;
  margin-top: 30px;
  text-transform: uppercase;
  border-radius: 0;
`

const SubmitRow = styled(Row)`
  justify-content: flex-end;
`

const ThankYou = styled.div`
  text-align: center;
  margin: 80px 0;
`

type State = {
  firstName?: string
  lastName?: string
  email?: string
  subject?: string
}

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  subject: ''
}

type Action = {
  payload: State
  type: typeof SET_FIELD
}

const FormCol = styled(Col)`
  padding: 0;

  @media (min-width: 960px) {
    padding-right: 0;
  }
`

const SET_FIELD = 'SET_FIELD'

function contactReducer(state: State, action: Action) {
  if (action.type === SET_FIELD) {
    return {
      ...state,
      ...action.payload
    }
  }

  return state;
}

export default function Contact({ data }: any) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [state, dispatch] = useReducer(contactReducer, initialState);
  const { firstName, lastName, email, subject } = state
  const isDt = useMediaQuery({minWidth: 960})
  const image = data.allContentfulPage?.nodes[0]?.images?.[0]?.fluid
  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    dispatch({ type: SET_FIELD, payload: initialState });
    // eslint-disable-next-line
    fetch('/.netlify/functions/contact',  {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(state)
    })
      // eslint-disable-next-line
      .then((d: any) => setIsSubmitted(true))
      // eslint-disable-next-line
      .catch((e: any) => console.log(e));
  }

  const onChange = (field: keyof State) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.currentTarget.value
    dispatch({
      type: SET_FIELD,
      payload: {
        [field]: value
      }
    })
  }

  return (
    <Layout>
      <SEO title="Contact us"/>
      <StyledGrid>
        <Row>
          <Col large={10} noGutters>
            {isDt && image && <Img fluid={image}/>}
          </Col>
          <Col offset={2} large={12} noGutters>
            <ContactField><strong>Say Hello!</strong></ContactField>
            <ContactField>
              <a href="tel:380665754708">tel.:380665754708</a>
              <a href="mailto:olena.t@ltstudio.com.ua">olena.t@ltstudio.com.ua</a>
            </ContactField>
            <ContactField><strong>Get in Touch</strong></ContactField>
            <SocialBlock>
              <Social>
                <a href="https://www.facebook.com/LTStudioLTStudio/">
                  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                       viewBox="0 0 155.139 155.139" xmlSpace="preserve">
                    <g>
                      <path id="f_1_" fill="#010002" d="M89.584,155.139V84.378h23.742l3.562-27.585H89.584V39.184
                      c0-7.984,2.208-13.425,13.67-13.425l14.595-0.006V1.08C115.325,0.752,106.661,0,96.577,0C75.52,0,61.104,12.853,61.104,36.452
                      v20.341H37.29v27.585h23.814v70.761H89.584z"/>
                    </g>
                  </svg>
                </a>
              </Social>
              <Social>
                <a href="https://www.instagram.com/ltstudio__lviv/">
                  <svg viewBox="0 0 512.00096 512.00096" xmlns="http://www.w3.org/2000/svg">
                    <path d="m373.40625 0h-234.8125c-76.421875 0-138.59375 62.171875-138.59375 138.59375v234.816406c0 76.417969 62.171875 138.589844 138.59375 138.589844h234.816406c76.417969 0 138.589844-62.171875 138.589844-138.589844v-234.816406c0-76.421875-62.171875-138.59375-138.59375-138.59375zm108.578125 373.410156c0 59.867188-48.707031 108.574219-108.578125 108.574219h-234.8125c-59.871094 0-108.578125-48.707031-108.578125-108.574219v-234.816406c0-59.871094 48.707031-108.578125 108.578125-108.578125h234.816406c59.867188 0 108.574219 48.707031 108.574219 108.578125zm0 0"/><path d="m256 116.003906c-77.195312 0-139.996094 62.800782-139.996094 139.996094s62.800782 139.996094 139.996094 139.996094 139.996094-62.800782 139.996094-139.996094-62.800782-139.996094-139.996094-139.996094zm0 249.976563c-60.640625 0-109.980469-49.335938-109.980469-109.980469 0-60.640625 49.339844-109.980469 109.980469-109.980469 60.644531 0 109.980469 49.339844 109.980469 109.980469 0 60.644531-49.335938 109.980469-109.980469 109.980469zm0 0"/><path d="m399.34375 66.285156c-22.8125 0-41.367188 18.558594-41.367188 41.367188 0 22.8125 18.554688 41.371094 41.367188 41.371094s41.371094-18.558594 41.371094-41.371094-18.558594-41.367188-41.371094-41.367188zm0 52.71875c-6.257812 0-11.351562-5.09375-11.351562-11.351562 0-6.261719 5.09375-11.351563 11.351562-11.351563 6.261719 0 11.355469 5.089844 11.355469 11.351563 0 6.257812-5.09375 11.351562-11.355469 11.351562zm0 0"/></svg>
                </a>
              </Social>
            </SocialBlock>
            <ContactField>Tell us about your project</ContactField>
            {!isSubmitted ? <form onSubmit={onSubmit} name="contact">
              <Row>
                <FormCol large={12}>
                  <StyledInput name="firstName" onChange={onChange('firstName')} type="text" placeholder="First Name" value={firstName}/>
                </FormCol>
                <FormCol large={12}>
                  <StyledInput name="lastName" type="text" placeholder="Last Name" value={lastName} onChange={onChange('lastName')}/>
                </FormCol>
              </Row>
              <Row>
                <FormCol large={24}>
                  <StyledInput required name="email" onChange={onChange('email')} type="mail" placeholder="Email" value={email} />
                </FormCol>
              </Row>
              <Row>
                <FormCol large={24}>
                  <StyledText name="subject" placeholder="Message" onChange={onChange('subject')}  value={subject} />
                </FormCol>
              </Row>
              <SubmitRow>
                <Submit type="submit" value="Submit"/>
              </SubmitRow>
            </form> : <ThankYou>Thank you, form submitted!</ThankYou>}
          </Col>
        </Row>
      </StyledGrid>
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulPage(filter: {name: {eq: "contacts"}}) {
      nodes {
        images {
          fluid(maxWidth: 300) {
              ...GatsbyContentfulFluid_withWebp
          }
        }
      }
  }
}
`
